import { API_AXIOS } from "../_interceptors/interceptor";


export const _notificationListApi = async () => {
  let url = '/notifications/unhidden'
  try {
    const { data } = await API_AXIOS({
      url: url,
      method: 'GET',
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};

export const _notificationClearApi = async (notificationId) => {
  let url = `/notification/${notificationId}/hide`
  try {
    const { data } = await API_AXIOS({
      url: url,
      method: 'PUT',
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};


