import React from 'react'
import { Box, Flex, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import logo from '../../../assets/logo.svg'
export const Doc = () => {
    return (
        <Box background={'#FFFFFF'} width={'100%'} p={6}>
            <Flex justifyContent={'space-between'} alignContent={'center'}>
                <Box>
                    <Text fontWeight={'bold'} fontSize={12} fontFamily={'Tahoma'}>Crystal Utilities Ltd</Text>
                    <Text fontFamily={'Tahoma'} fontSize={12} >Unit-2, Midland House</Text>
                    <Text fontFamily={'Tahoma'} fontSize={12}>111-113 Victoria Road</Text>
                    <Text fontFamily={'Tahoma'} fontSize={12}>Romford</Text>
                    <Text fontFamily={'Tahoma'} fontSize={12}>RM1 2LX</Text>
                    <Text fontWeight={'bold'} fontSize={12} fontFamily={'Tahoma'}>VAT Registration Number : 144732418</Text>
                </Box>
                <Image src={logo} minH={145} minW={145} />
            </Flex>
            <Text align={'center'} py={5} fontWeight={'bold'} fontSize={12} fontFamily={'Tahoma'}>Invoice</Text>
            <Flex justifyContent={'space-between'} alignContent={'center'}>
                <Box>
                    <Text fontWeight={'bold'} fontFamily={'Tahoma'} fontSize={12}>British Gas</Text>
                    <Text fontFamily={'Tahoma'} fontSize={12} >Spinnneyside</Text>
                    <Text fontFamily={'Tahoma'} fontSize={12}>Leicester</Text>
                    <Text fontFamily={'Tahoma'} fontSize={12}>LE19 1SZ</Text>
                </Box>
                <Box>
                    <Text fontWeight={'bold'} fontFamily={'Tahoma'} fontSize={12} >Invoice No:  Nov-23</Text>
                    <Text fontFamily={'Tahoma'} fontSize={12} >Date:    17-10-2023</Text>
                    <Text fontFamily={'Tahoma'} fontSize={12} >Vendor   192828</Text>
                </Box>
            </Flex>
            <TableContainer pt={10} width="100%">
                <Table variant='striped' colorScheme='whiteAlpha' size='sm' >
                    <Thead style={{ position: "relative", paddingTop: "15px" }}>
                        <Tr style={{ zIndex: 8, position: "sticky", top: 0 }}>
                            <Th fontFamily={'Tahoma'} fontWeight={'bold'} fontSize={12} color={'#000000'} pl={0}>Business Name</Th>
                            <Th fontFamily={'Tahoma'} fontWeight={'bold'} fontSize={12} color={'#000000'}>MPAN/MPRN</Th>
                            <Th fontFamily={'Tahoma'} fontWeight={'bold'} fontSize={12} color={'#000000'}>Supplier</Th>
                            <Th fontFamily={'Tahoma'} fontWeight={'bold'} fontSize={12} color={'#000000'}>Contract Length</Th>
                            <Th fontFamily={'Tahoma'} fontWeight={'bold'} fontSize={12} color={'#000000'}>Consumption</Th>
                            <Th fontFamily={'Tahoma'} fontWeight={'bold'} fontSize={12} color={'#000000'}>Uplift</Th>
                            <Th fontFamily={'Tahoma'} fontWeight={'bold'} fontSize={12} color={'#000000'}>Live date</Th>
                            <Th fontFamily={'Tahoma'} fontWeight={'bold'} fontSize={12} color={'#000000'}>Commission</Th>
                            <Th fontFamily={'Tahoma'} fontWeight={'bold'} fontSize={12} color={'#000000'}>Agents</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            [1, 1, 1, 1, 1, 1, 6, 709, 85,].map((data, indx) => {
                                return (
                                    <Tr key={indx}>
                                        <Td fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'} pl={0} >ALIS TAKEAWAY</Td>
                                        <Td fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'}>8899802103</Td>
                                        <Td fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'}>British Gas</Td>
                                        <Td fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'}>24 months</Td>
                                        <Td fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'}>38,910</Td>
                                        <Td fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'}>0.8</Td>
                                        <Td fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'}>09-05-2023</Td>
                                        <Td fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'}>435.79</Td>
                                        <Td fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'}>Prime utilities</Td>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <Box pt={20} display={"flex"} justifyContent={"flex-end"}>
                <Box>
                    <Text fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'}>Total Amount   3,467.54</Text>
                    <Text fontFamily={'Tahoma'} fontWeight={'bold'} fontSize={12} color={'#000000'} >Total VAT Amount <span style={{ fontFamily: 'Tahoma', fontWeight: 300, fontSize: 12, color: '#000000' }}>693.51</span> </Text>
                    <Text fontFamily={'Tahoma'} fontWeight={'bold'} fontSize={12} color={'#000000'}>Invoice Total   4,161.05</Text>
                </Box>
            </Box>
            <Box pt={2}>
                <Text fontFamily={'Tahoma'} fontWeight={'bold'} fontSize={12} color={'#000000'}>Remittance Information</Text>
                <Text fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'}>All amounts are in GBP £ unless otherwise stated.</Text>
                <Text fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'} >Electronic Banking Information:</Text>
                <Text fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'} >Bank Name: ABC</Text>
                <Text fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'} >Sort Code: 00-00-00</Text>
                <Text fontFamily={'Tahoma'} fontWeight={300} fontSize={12} color={'#000000'} >Account Number: 00000000</Text>
            </Box>
        </Box>
    )
}