// /esign/Esign.jsx
import { Box, Button, Spinner, Text, Image } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import errorAnimation from './error.json';
import successAnimation from './success.json';
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import SignPad from "./SignPad2";
import CustomLink from "../components/CustomLink";
import { MdKeyboardArrowLeft } from "react-icons/md";
import axios from "axios";
import { submitSignature } from "./e-signatureService";

const Esign = () => {
  const { code } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [docCreate, setDocCreate] = useState({
    isSuccess: false,
    file: undefined,
    message: "Document Signed Successfully",
  })
  const [signData, setSignData] = useState({
    isSuccess: false,
    file: undefined,
    message: "Document saved Successfully",
  });

  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [userName, setUserName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [currentSupplier, setCurrentSupplier] = useState('');

  const updateSignData = (data) => {
    setSignData(data);
  };

  useEffect(() => {
    fetchPdf();
  }, []);

  // Effect to create an object URL for the PDF Blob and set it to pdfUrl
  useEffect(() => {
    if (pdfBlob) {
      const objectUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(objectUrl);

      // Clean up object URL when component unmounts
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [pdfBlob]);

  const fetchPdf = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/contract/esign/${code}`);

      if (response.status === 200) {
        const { contract, pdf, contentType } = response.data;
        const { title, firstName, surName, job_title } = contract.otherInformationData['Customer Details'];
        const { Supplier } = contract.otherInformationData['Selling Price'];

        // Set user details for signature pad
        setUserName(`${title} ${firstName} ${surName}`);
        setJobTitle(job_title);
        setCurrentSupplier(Supplier);

        // Convert Base64 PDF to Blob and update state
        const pdfBlob = base64ToBlob(pdf, contentType || 'application/pdf');
        setPdfBlob(pdfBlob);
      } else {
        setError('Failed to retrieve contract PDF');
      }
    } catch (err) {
      console.error('Error fetching contract:', err);
      setError('Unable to fetch contract PDF');
    } finally {
      setLoading(false);
    }
  };

  // Helper function to convert Base64 string to Blob
  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  // Handle submission of signature
  const handleSubmitSignature = async () => {
    try {
      const formData = new FormData();
      formData.append("file", signData.file); // Ensure `signData.file` is defined
      const submitResult = await submitSignature(formData, code);
      if (submitResult.status == 200) {
        setDocCreate({
          isSuccess: true,
          file: submitResult.data.data.file,
          message: "Document Signed Successfully",
        });
      }
      else {
        setError(submitResult.data.message)
      }
    } catch (error) {
      setError("Error during submission");
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minH="100vh">
        <Spinner size="xl" />
        <Text fontWeight={600} fontSize={20}>Loading...</Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minH="100vh" flexDirection="row">
        <Lottie animationData={errorAnimation} loop={false} autoplay={true} style={{ height: 300, width: 300 }} />
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} style={{ fontWeight: 600, fontSize: 20, marginLeft: "20px", textAlign: "center" }}>
          {error}
        </motion.div>
      </Box>


    );
  }

  if (docCreate.isSuccess) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minH="100vh" flexDirection="row">
        <Lottie animationData={successAnimation} loop={false} autoplay={true} style={{ height: 300, width: 300 }} />
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "20px" }} >
          <Text fontWeight={600} fontSize={20}> {signData.message}</Text>
          <CustomLink type="primary" to={`${process.env.REACT_APP_BASE_URL}/file/${docCreate.file}?download=true`} newTab={true} style={{ marginTop: "10px" }} >  Download Signed Document</CustomLink>
          <CustomLink type="basic" to="/" style={{ display: "inline-flex", alignItems: "center", marginTop: "10px" }}>
            <MdKeyboardArrowLeft fontSize="25px" />
            Back to Home
          </CustomLink>
        </motion.div>
      </Box>


    );
  }

  return (
    <Box width="100%" display="grid" placeItems="center" padding="20px">
      <Box width={[300, 600, 900]}>

        {/* Header */}
        <Box display="flex" alignItems="center" gap={2} marginBottom="20px" paddingY="20px" borderBottom="2px solid black">
          <Image src="/logo.svg" />
          <Text fontWeight={600}>Crystal Utilities</Text>
        </Box>

        {/* PDF Display */}
        {pdfUrl && (
          <Box marginTop="50px" marginBottom="20px">
            <Text fontWeight={600} fontSize={20}>Contract PDF</Text>
            <iframe src={pdfUrl} width="100%" height="600px" style={{ border: 'none' }} title="Contract PDF" />
          </Box>
        )}

        {/* Signature Pad */}
        <Box marginTop="50px" marginBottom="20px">
          <Text fontWeight={600} fontSize={20}>Sign Here</Text>
          <SignPad code={code} updateSignData={updateSignData} pdfBlob={pdfBlob} userName={userName} jobTitle={jobTitle} currentSupplier={currentSupplier} />
        </Box>

        {/* Submit Button */}
        <Box marginTop="20px">
          <Button
            onClick={handleSubmitSignature}
            isDisabled={!signData.isSuccess}
          >
            Submit Signature
          </Button>
          {!signData.isSuccess}
        </Box>
      </Box>
    </Box>
  );
};

export default Esign;
