import React from 'react'
import { Flex, Spinner } from '@chakra-ui/react'

const LoadingButton = ({text="Please wait..."}) => {
    return (
        <Flex h={'100%'} w={'100%'} justifyContent={'center'} alignItems={'center'} gap={3} fontFamily={'Inter'} fontSize={14} fontWeight={500}>
            <Spinner size='md' />
            <p>{text}</p>
        </Flex>
    )
}

export default LoadingButton