import Shape from '../assets/Shape.png'
import usersidbr from '../assets/usersidbr.svg'
import contractSid from '../assets/contractSid.svg'
import merchantSid from '../assets/merchantSid.svg'
import commissionSId from '../assets/commissionSId.svg'
import reportSid from '../assets/reportSid.svg'
import notificationSid from '../assets/notificationSid.svg'
import messageSid from '../assets/messageSid.svg'
import deleteSid from '../assets/deleteSid.svg'
import accountSid from '../assets/accountSid.svg'
import invoiceSid from '../assets/invoiceSid.svg'

export const superAdminidbar = [
  { label: "Dashboard", href: "/dashboard", icon: Shape },
  // { label: "All User", href: "/users", icon: usersidbr },

  {
    label: "All User", icon: usersidbr,                   // Don't change spelling and text, it used in sidebar
    children: [
      { label: "View Employee", href: "/users", icon: usersidbr },
      { label: "View Agent", href: "/users", icon: usersidbr },
    ]
  },
  { label: "Contracts", href: "/contracts", icon: contractSid },
  { label: "Merchants", href: "/merchants", icon: merchantSid },
  { label: "Commission", href: "/commissions", icon: commissionSId },
  { label: "Reports", href: "/reports", icon: reportSid },
  { label: "Notifications", href: "/notifications", icon: notificationSid },
  { label: "Messages", href: "/chats", icon: messageSid },
  { label: "Deleted Data", href: "/deleted", icon: deleteSid },
  {
    label: "Accounts", icon: accountSid,
    children: [
      { label: "Invoices", href: "/invoices", icon: invoiceSid },
    ]
  },
];

export const adminSidebar = [
  { label: "Dashboard", href: "/dashboard", icon: Shape },
  // { label: "All User", href: "/users", icon: usersidbr },
  {
    label: "All User", icon: usersidbr,                   // Don't spelling and text, it used in sidebar
    children: [
      { label: "View Employee", href: "/users", icon: usersidbr },
      { label: "View Agent", href: "/users", icon: usersidbr },
    ]
  },
  { label: "Contracts", href: "/contracts", icon: contractSid },
  { label: "Merchants", href: "/merchants", icon: merchantSid },
  { label: "Commission", href: "/commissions", icon: commissionSId },
  { label: "Reports", href: "/reports", icon: reportSid },
  { label: "Notifications", href: "/notifications", icon: notificationSid },
  { label: "Messages", href: "/chats", icon: messageSid },
  {
    label: "Accounts", icon: accountSid,
    children: [
      { label: "Invoices", href: "/invoices", icon: invoiceSid },
    ]
  },
];

export const agentSidebar = [
  { label: "Dashboard", href: "/dashboard", icon: Shape },
  { label: "Contracts", href: "/contracts", icon: contractSid },
  { label: "Merchants", href: "/merchants", icon: merchantSid },
  { label: "Commission", href: "/commissions", icon: commissionSId },
  { label: "Notifications", href: "/notifications", icon: notificationSid },
  { label: "Messages", href: "/chats", icon: messageSid },
  {
    label: "Accounts", icon: accountSid,
    children: [
      { label: "Invoices", href: "/invoices", icon: invoiceSid },
    ]
  },
];

export const validateForm = async ({ validationSchema, form, setErrors }) => {
  try {
    await validationSchema.validate(form, { abortEarly: false });
    setErrors({});
    return true;
  } catch (err) {
    const newErrors = {};
    err?.inner?.forEach((error) => {
      let path = error.path;
      newErrors[path] = error.message;
    })


    setErrors(newErrors);
    return false;
  }
}


export const updateErrors = async ({ key, value, form, validationSchema, setErrors }) => {
  // Clear the error for this field as soon as the user types
  setErrors((prevErrors) => ({
    ...prevErrors,
    [key]: undefined,
  }));

  try {
    // Create a copy of the form with the updated field value
    const updatedForm = { ...form };
    key.split('.').reduce((acc, part, idx, arr) => {
      if (idx === arr.length - 1) {
        acc[part] = value;
      } else {
        acc[part] = acc[part] || {};
      }
      return acc[part];
    }, updatedForm);

    // Validate the specific field
    await validationSchema.validateAt(key, updatedForm);
  } catch (err) {
    // Set the error message for the specific field

    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: err.message,
    }));
  }
}


export const capitalizeFirstLetter = (strr) => {

  if (!strr) return strr; // Handle empty or undefined strings
  let str = strr.toString();
  return str?.charAt(0).toUpperCase() + str.slice(1);
};


export const searchSuperAdmin = [
  { label: "Dashboard", value: "/" },

  { label: "Users", value: "users" },
  { label: "Employees", value: "/users?state=employee" },
  { label: "Add Employee", value: "/users/add-employee" },
  { label: "Agents", value: "/users/?state=agents" },
  { label: "Add Agent", value: "/users/add-agent" },

  { label: "Contracts", value: "/contracts" },
  { label: "Add Contract", value: "/contracts/postCode-mprn" },

  { label: "Merchants", value: "/merchants" },

  { label: "Commissions", value: "/commissions" },
  { label: "Add Commission", value: "/commission-add" },

  { label: "Reports", value: "/reports" },
  { label: "Messages", value: "/chats" },
  { label: "Notifications", value: "/notifications" },
  { label: "Add Notification", value: "/notification-add" },

  { label: "Deleted Data", value: "/deleted" },
]

export const searchAdmin = [
  { label: "Dashboard", value: "/" },

  { label: "Users", value: "users" },
  { label: "Employees", value: "/users?state=employee" },
  { label: "Add Employee", value: "/users/add-employee" },
  { label: "Agents", value: "/users/?state=agents" },
  { label: "Add Agent", value: "/users/add-agent" },

  { label: "Contracts", value: "/contracts" },
  { label: "Add Contract", value: "/contracts/postCode-mprn" },

  { label: "Merchants", value: "/merchants" },

  { label: "Commissions", value: "/commissions" },
  { label: "Add Commission", value: "/commission-add" },

  { label: "Reports", value: "/reports" },
  { label: "Messages", value: "/chats" },
  { label: "Notifications", value: "/notifications" },
  { label: "Add Notification", value: "/notification-add" },
]

export const searchAgent = [
  { label: "Dashboard", value: "/" },
  { label: "Contracts", value: "/contracts" },
  { label: "Add Contract", value: "/contracts/postCode-mprn" },
  { label: "Merchants", value: "/merchants" },
  { label: "Commissions", value: "/commissions" },
  { label: "Notifications", href: "/notifications" },
  { label: "Messages", value: "/chats" },
]


export const addDotWithText = (text, count = 10) => {
  if (text?.length > count) {
    return capitalizeFirstLetter(text?.substring(0, count) + "...");
  } else {
    return capitalizeFirstLetter(text);
  }
}