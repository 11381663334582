import React, { useEffect } from 'react'
import { Box, Button, Flex, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import LoadingButton from '../LoadingButton';
import cross from '../../assets/cross.svg';
import { capitalizeFirstLetter } from '../../utils/menuItems';
import { useMutation } from 'react-query';
import { _notificationClearApi } from '../HeaderApi';
import Loading from '../Loading';

const NotificationPopUp = ({ isOpen, onClose, data = {}, loading = false, refetch }) => {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto'; // Clean up on unmount
        };
    }, [isOpen]);


    const { mutate, isLoading } = useMutation(_notificationClearApi, {
        onSuccess: () => {
            onClose()
            refetch()
        },
        onError: (error) => {
            console.error('Notifiaction failed:', error);
        }
    });

    return (
        <Box>
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered={true}>
                <ModalOverlay bg='rgba(255,255,255, 0.1)' backdropFilter='blur(3px)' overflowY="hidden" />
                <ModalContent border="0.7px solid #979797" borderRadius="md" boxShadow={'md'} py={1} px={'0px !importent'}>
                    <ModalHeader>
                        <Flex alignItems={'center'}>
                            <Flex
                                justifyContent="center"
                                flex="1"
                                fontFamily="Inter"
                                fontSize={19}
                                fontWeight={700}
                                color="#202020"
                            >
                                Notification
                            </Flex>
                            <Image src={cross} display="flex" h={5} w={5} cursor={'pointer'} onClick={onClose}
                            />
                        </Flex>
                    </ModalHeader>

                    <ModalBody>
                        <Box boxShadow={'sm'}>
                            <Box p={4} pb={1}>
                                <Text fontFamily={'Inter'} textAlign={'center'} fontSize={14} fontWeight={600}>{capitalizeFirstLetter(data?.title)}</Text>
                                <Box h={280} overflowY={'auto'} className='dashboard'>

                                    {
                                        loading ? <Flex justifyContent={'center'} pt={20} alignItems={'center'}><Loading /></Flex> :
                                            <Text fontFamily={'Inter'} fontSize={12} fontWeight={500}>
                                                {capitalizeFirstLetter(data?.message)}
                                            </Text>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Box display={'flex'} justifyContent={'flex-end'} w={'100%'}>
                            <Button color={"#FFFFFF"} bg={'#40B6FD'} _hover={{ bg: "#1b8bd1", color: "white" }} borderRadius={10.28} fontFamily={'Inter'} fontSize={14.06} fontWeight={600} onClick={() => mutate(data?.notificationId)}>
                                {isLoading ? <LoadingButton /> : 'Read and understood'}
                            </Button>
                        </Box>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </Box>
    )
}

export default NotificationPopUp;



