import React from 'react'
import { Box, Spinner } from '@chakra-ui/react'

const Loading = ({ text = "", size = 'lg' }) => {
    return (
        <Box display={'flex'} h={'100%'} w={'100%'} alignItems={'center'} justifyContent={'center'} >
            <Spinner size={size} />
        </Box>
    )
}

export default Loading