import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isContractView: 'view',
  meterDetailPayload: {}
}

export const contract = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    isContractView: (state, action) => {
      state.isContractView = action?.payload ?? 'view';
    },

    meterDetailPayload: (state, action) => {
      state.meterDetailPayload = action?.payload ?? {};
    },
  },
});

export const { isContractView, meterDetailPayload } = contract.actions;
export default contract.reducer;


