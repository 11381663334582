// coordinates.js

const supplierCoordinates = {
    "British Gas": {
        1: {
            signature: { xPercent: 0.60, yPercent: 0.255, widthPercent: 0.2, heightPercent: 0.035 }, // Signature on the right side, near the bottom
            signingDate: { xPercent: 0.60, yPercent: 0.24 }, // SigningDate below jobTitle
        },
        2: {
            signature: { xPercent: 0.70, yPercent: 0.15, widthPercent: 0.2, heightPercent: 0.075 }, // Signature on the right side, near the bottom
            userName: { xPercent: 0.70, yPercent: 0.135 },  // UserName closer to signature
            jobTitle: { xPercent: 0.70, yPercent: 0.112 },  // JobTitle below userName
            signingDate: { xPercent: 0.70, yPercent: 0.09 }, // SigningDate below jobTitle
        },
        3: {
            signature: { xPercent: 0.70, yPercent: 0.15, widthPercent: 0.2, heightPercent: 0.075 }, // Signature on the right side, near the bottom
            userName: { xPercent: 0.70, yPercent: 0.135 },  // UserName closer to signature
            jobTitle: { xPercent: 0.70, yPercent: 0.112 },  // JobTitle below userName
            signingDate: { xPercent: 0.70, yPercent: 0.09 }, // SigningDate below jobTitle
        }
    },
    "British Gas Lite": {
        1: {
            signature: { xPercent: 0.66, yPercent: 0.061, widthPercent: 0.2, heightPercent: 0.035 }, // Signature on the right side, near the bottom
            signingDate: { xPercent: 0.68, yPercent: 0.01 }, // SigningDate below jobTitle
        }
    },
    "Scottish Power": {
        2: {
            signature: { xPercent: 0.165, yPercent: 0.39, widthPercent: 0.2, heightPercent: 0.05 }, // Signature on the left
            signingDate: { xPercent: 0.5, yPercent: 0.415 }, // Date on the right of signature
        },
        3: {
            signature: { xPercent: 0.07, yPercent: 0.035, widthPercent: 0.2, heightPercent: 0.04 }, // Signature on the left
            signingDate: { xPercent: 0.278, yPercent: 0.052 }, // Date on the right of signature
        }
    },
    "EDF": {
        2: {
            signature: { xPercent: 0.1, yPercent: 0.6, widthPercent: 0.2, heightPercent: 0.04 }, // Signature on the left
            signingDate: { xPercent: 0.59, yPercent: 0.578 }, // Date on the right of signature
        }
    },
    "Scottish and Southern": {
        1: {
            signature: { xPercent: 0.3, yPercent: 0.19, widthPercent: 0.2, heightPercent: 0.04 }, // Signature on the left
            signingDate: { xPercent: 0.7, yPercent: 0.196 }, // Date on the right of signature
        }
    },
    "Clear Business": {
        1: {
            signature: { xPercent: 0.3, yPercent: 0.19, widthPercent: 0.2, heightPercent: 0.04 }, // Signature on the left
            signingDate: { xPercent: 0.7, yPercent: 0.196 }, // Date on the right of signature
        }
    },
    "Utilita": {
        5: {
            signature: { xPercent: 0.64, yPercent: 0.816, widthPercent: 0.2, heightPercent: 0.028 }, // Signature on the left
            signingDate: { xPercent: 0.631, yPercent: 0.78 }, // Date on the right of signature
        }
    }
};

export const getCoordinatesBySupplier = (supplierName) => {
    return supplierCoordinates[supplierName] || null;
};
